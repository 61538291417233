
.login_panel {
  width: 400px;
  height: 180px;
  border: 1px solid #c0c0c0;
  background-color: #f5f5f5;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  border-radius: 5px;
  padding-top: 10px;
}

.login_panel_row {
  width: 100%;
  height: 40px;
  /* background-color: #c0c0c0; */
  margin-top: 12px;
  padding-left: 30px;
  padding-right: 30px;
}

.login_panel_label {
  width: 130px;
  display: inline-block;
}

.login_panel_input {
  width: 190px;
  margin-top: 5px;
}

.login_panel_submit {
  float: right;
  margin-right: 20px;
  margin-top: 5px;
}

.login_panel_message {
  float: left;
  font-size: 13px;
  font-style: italic;
  color: darkred;
  height: 100%;
  line-height: 40px;
}