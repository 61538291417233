.full_width_container {
  max-width: 100%;
}

.store-menu-page-container {
  /* padding-left: 10px;
  padding-right: 10px; */
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.menu_header {
  width: 100%;
  margin: 0px;
  font-size: 1.5rem;
  font-weight: 700;
}

.menu_details_accordion {
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu_section_title {
  width: 100%;
  margin: 0px;
  font-size: 18px;
  font-weight: 700;
  text-overflow: ellipsis;
}

.menu_section_subtitle {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  text-overflow: ellipsis;
  color: #606060;
  line-height: 20px;
  padding-top: 5px;
}

.item_section_div {
  border-bottom: 1px solid #e0e0e0;
  height: 100px;
}

.item_section_div_last {
  height: 100px;
}

.item_section_name {
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  padding-top: 11px;
  color: black;
}

.item_section_description {
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #808080;
}

.item_section_price {
  height:38px;
  font-size: 14px;
  padding-top: 8px;
  color: black;
}

.item_section_image_row {
  height: 100%;
  width: 100%;
}

.item_section_image {
  max-width: 100%;
  max-height: 95%;
}

.item_section_image_container {
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.store_menu_page_store_logo_container {
  max-height: 70px;
  max-width: 100%;
  width: 100%;
  display: block;
  float: left;
  margin-bottom: 5px;
}

.store_menu_page_store_logo {
  max-width: 100%;
  max-height: 70px;
  margin-left: 0px;
  margin-right: auto;
  display: block;
}

.menu-selector-container {
  display: block;
  padding-top: 15px;
  padding-bottom: 12px;
  width: 100%;
  text-align: center;
  height: 65px;
}

.menu_selector_dropdown {
  display: block;
  float: right;
}

.menu-selector {
  width: 100%;
}

.dropdown-item-spacer {
  padding-left: 32px !important;
}

.dropdown-item-checked::before {
  position: absolute;
  left: 10px;
  content: '✓';
  font-weight: 600;
}

.item-details-container {
  padding-left: 10px;
  padding-right: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.item-details-modal {
  padding-left: 10px;
  padding-right: 10px;
}

.item-details-name {
  /* font-size: 25px; */
  /* font-weight: bold; */
  /* padding-top: 5px; */
  /* padding-bottom: 15px; */
  text-overflow: ellipsis;
  padding-right: 10px;
}

.item-details-description {
  font-size: 14px;
  color: #606060;
  padding-bottom: 20px;
}

.item-details-image {
  margin-bottom: 20px;
  width: 100%;
  padding-right: 10px;
}

.item-option-details-checkbox {
  float: left;
  display: block;
  width: 40px;
  height: 38px;
}

.item-option-details-option-name {
  padding-top: 8px;
  text-indent: 3px;
  font-size: 14px;
}

.float-right {
  float: right;
}