
.quantity-selector-container {
  width: 220px;
  display: inline;
}

.quantity-selector-text-control {
  width: 55px;
  display: inline-block;
}

.quantity-selector-text-control-input {
  text-align: center;
}